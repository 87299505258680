// ==========================================================================
// FOOTER
// ==========================================================================

.footer {
  background: #fafafa;
  padding-top: 10rem;
  padding-bottom: 10rem;
  line-height: 1.9;
}

  .footer__column {
    margin-bottom: 3rem;
    ul{
      padding: 0;
      list-style: none;

      li a{
        color: #a3a3a3;
        display: block;
        padding: 0 0 10px;

        &:hover,
        &:active {
          text-decoration: none;
        }
      }
    }

  }

  .footer__column h5 {
      font-weight: bold;
      color: #00bffd;
      text-transform: uppercase;
      margin-bottom: 2.5rem;

    }

    .footer__socials {
      margin-left: -1rem;
    }

      .footer__socials__item {
        width: 35px;
        display: inline-block;
        font-size: 25px;
        text-align: center;

        &:link,
        &:hover,
        &:active{
          text-decoration: none;
        }

        i{
          color: #D0D0D0;
          transition: color 0.25s ease-in-out;

          &.ir-icon-facebook:hover {
            color: #3B5998;
          }
          &.ir-icon-linkedin:hover {
            color: #007BB5;
          }
          &.ir-icon-twitter:hover {
            color: #00ACED;
          }
          &.ir-icon-github:hover {
            color: #181616;
          }
        }

      }

      .footer__logo {
        opacity: 0.2;
        transition: opacity 0.35s ease;
        margin-top: 2rem;

        &:hover,
        &:active,
        &:focus {
          opacity: 1;
        }
      }

@media screen and (max-width: $screen-sm-max) {
  .footer .widget_nav_menu+.widget_text{
    clear: left;
  }
}

@media screen and (min-width: $screen-md-min) {
  .footer .footer__column{
    width: 20%;
  }
  .footer .widget_nav_menu + .widget_text{
    width: 35%;
    padding-right: 5%;
  }

}

@media screen and (max-width: $screen-xs-max) {

  .footer{
    text-align: center !important;
  }

}

