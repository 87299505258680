// ==========================================================================
// HEADER
// ==========================================================================

//
// Navbar
// ==========================================================================
.navbar {
  font-size: 1.5rem;
}

.navbar-brand {
  padding-top: 2.6rem;
}

  .navbar-brand__logo {
    max-width: 28rem;

    @media (max-width: 400px) {
      max-width: 22.5rem;
    }

    @media (max-width: 350px) {
      max-width: 19rem;
    }
  }

  .navbar-toggle[data-target="#navbar"] {
    border: none;
    &:focus,
    &:hover {
      background-color: transparent;
    }

    &.collapsed {
      .top-bar { transform: rotate(0); }
      .middle-bar { opacity: 1; }
      .bottom-bar { transform: rotate(0); }
    }

    .icon-bar {
      width: 28px;
      height: 2px;
      border-radius: 5px;
      transition: all 0.2s ease-in-out;
    }

    .top-bar {
      transform: rotate(45deg) translate(1px, -6px);
      transform-origin: top left;
    }

    .middle-bar {
      opacity: 0;
    }

    .bottom-bar {
      transform: rotate(-45deg) translate(1px, 6px);
      transform-origin: bottom left;
    }
  }


//
// Hero
// ==========================================================================
.page__hero {
  color: #fff;
  background-image:
    linear-gradient(231deg,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 189, 251, 0.5) 100%),
    url(../images/coach-logan-corona-centennial-reveal-sideline-replay.jpg);
  background-size: cover;
  padding-top: 3em;
  padding-bottom: 4em;
  margin-bottom: 0;
}

  .hero__title {
    font-size: 2.3em;
    font-weight: 800;
    line-height: 1.406249em;
    margin-bottom: 1em;
  }

  .hero__subtitle {
    font-size: 1.714289em;
    font-weight: 300;
    line-height: 1.375em;
    margin-bottom: 2em;
  }

    .strong-win-more {
      padding-bottom: 3px;
      background: url(../images/win-more.png) bottom center no-repeat;
      background-size: contain;

      // Break heading in small mobiles to keep underline consistent
      @media (max-width: 400px) {
        & {
          display: inline-block;
        }
      }
    }

.hero__form-container {
  transform: translateY(4em);
}

  .hero__form {
    background: #fff;
    padding: 2.5rem;
    border-radius: 0.5rem;
    color: #adadaa;
    box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.13);
  }

    .hero__form__header {
      color: #00BFFD;
      font-size: 1.14286em;
      font-weight: bold;
      margin-bottom: 1.125em;
    }
