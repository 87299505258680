// Gravity Forms styles overrides

.gform {
  input,
  select,
  textarea {
    width: 100%;
    border: 1px solid #D0D0D0;
    padding: 5px 7px;
    border-radius: 2px;
    color: #111;
  }

  label {
    font-weight: normal;
  }
}

.gform_heading {
  color: #00BFFD;
  font-size: 1.14286em;
  font-weight: 700;
  margin-bottom: 1.125em;
}

.gform_fields {
  margin: 0;
  padding: 0;
  list-style: none;
}

.gfield {
  margin-bottom: 1.5em;
}

.form__item {
  margin-bottom: 15px;
}


.gform select {
  -webkit-appearance : none;
  -moz-appearance : none;
  background : none;

}

.contact__form {
  background: #fff;
  padding: 2.5rem;
  border-radius: 0.5rem;
  color: #adadaa;
  box-shadow: 0px 2px 9px 0px rgba(0,0,0,0.13);
}
