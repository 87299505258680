
a,
a:hover{
  color: $link-color;
}

.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/// Mixin printing a linear-gradient
/// as well as a plain color fallback
/// and the `-webkit-` prefixed declaration
/// @access public
/// @param {Keyword | Angle} $direction - Linear gradient direction
/// @param {Arglist} $color-stops - List of color-stops composing the gradient
@mixin linear-gradient($direction, $color-stops...) {
  background: nth(nth($color-stops, 1), 1);
  background: -webkit-linear-gradient(legacy-direction($direction), $color-stops);
  background: linear-gradient($direction, $color-stops);
}

.main{
  // padding: 0;
  padding-bottom: 50px;
}

//
// Remodal overwrites
// ==========================================================================
.remodal iframe{
  max-width: 100%;
}
.remodal {
 width: auto;
 max-width: none;
 padding: 15px 15px 9px;

  .remodal-close{
    left: auto;
    right: 10px;
    top: -35px;
    width: 35px;
    height: 35px;
    position: absolute;

    &:before{
      font-size: 35px;
      width: 50px;
      color: #fff;
    }
  }

}

//
// Bootstrap overwrites
// ==========================================================================

.modal.video-player{
  top: 10%;
  @media screen and (max-width: $screen-xs-max) {
    .modal.video-player{
      border: 1px solid red;
    }
  }
}
.btn,
.comment-form input[type=submit],
.search-form .search-submit{
  padding: 9px 12px;
}

.btn.btn-primary{
  background: linear-gradient(#00bffd, #0197c8);
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}
@media screen and (max-width: $screen-xs-max) {

  .navbar-nav {
    a{
      color: #777 !important;
    }
    .dropdown-menu {
      >.active>a,
      >.active>a:focus,
      >.active>a:hover{
        color: #fff !important;
      }
      display: block;
      position: static;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      -webkit-box-shadow: none;
      box-shadow: none;
      >li>a {
        padding: 5px 15px 5px 25px;
      }
    }
  }
}

@media screen and (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
  .navbar-brand__logo{
    max-width: 18rem !important;
    height: auto;
    margin-top: 4px;
  }

  .navbar-nav>li>a{
    font-size: 1.3rem;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.comment-form input[type=text],
.comment-form input[type=email],
.comment-form input[type=url],
.comment-form textarea,
.form-control, .search-form .search-field{
  height: 40px;
}
.ginput_container input{
  @extend .form-control;
}

.no-padding{
  padding-left: 0;
  padding-right: 0;
}

//
// Reusable
// ==========================================================================

.section__header {
  margin: 5rem auto;
}
.ir-reveal-tm{
  font-variant: normal;
  font-variant-position: super; 
}
.section-sm-header {
  color: #b0b0b0;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 1.1em;
}
.section-sm-header-plus{
  color: #b0b0b0;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  font-size: 1.8em;
}
.section-lg-header {
  margin: 0;
  font-size: 2.5em;
}

.text-no-transform {
  text-transform: none;
}

.marked{
  padding-bottom: 3px;
  background: url(../images/win-more.png) bottom center no-repeat;
  background-size: contain;
}

.is-narrow {
  max-width: 50em;
}

.is-dark {
  color: $dark-color;
}

// ==========================================================================
// INTRO
// ==========================================================================

.intro-wrapper {
  padding-top: 10rem;
  padding-bottom: 5rem;
}

  .intro__middle {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

    .intro__ipad {
      transform: translateX(-35rem);
    }

    .intro__promo-vid {
      padding: 10rem 0;

      a {
        text-decoration: none;
        color: #00bffd;
      }
    }

      .intro__promo-vid-container {
        position: relative;
        cursor: pointer;
      }

        .intro__promo-vid__cover {
          width: 100%;
          height: 100%;
        }
        .intro__promo-vid__play {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 60px;
          height: 60px;
          background: url(../images/ic_play-lg.png) no-repeat center center;
        }






// ==========================================================================
// CATEGORIES
// ==========================================================================

.categories-wrapper {
  background: #e8f0f3;
  padding: 4rem 0;
}

  .categories__link {
    margin-top: 2rem;
    margin-bottom: 2rem;
    cursor: pointer;
    color: $link-color;
  }

    .categories__image {
      margin-right: 2.5rem;
      display: inline-block;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      .categories__image {
        margin-right: 0.5rem;
      }
    }


    .categories__copy {
      display: inline-block;
      vertical-align: middle;
    }

      .categories__header {
        font-weight: 300;
        margin: 0;
      }






// ==========================================================================
// HOW IT WORKS
// ==========================================================================

.how-it-works-wrapper {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

  .how-it-works__illustration {
    margin: 5rem auto;

    @media (max-height: 735px) {
      width: 70%;
      margin: 2rem auto 0;
    }
  }

  .how-it-works__step {
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

    .how-it-works__ordinal {
      width: 50px;
      height: 50px;
      display: block;
      background: #c6d634;
      border-radius: 50em;
      margin: 0 auto;
      line-height: 50px;
      vertical-align: middle;
      color: #fff;
      font-weight: bold;
      font-size: 1.714283em;
      margin-bottom: 2rem;
    }






// ==========================================================================
// BENEFITS
// ==========================================================================

.benefits-wrapper {
  color: #fff;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: linear-gradient(-43deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 189, 251, 0.75) 100%), url(../images/benefits-bg.jpg);
  background-size: cover;
}

  .benefits__header {
    padding-top: 5rem;
    padding-bottom: 5rem;

    .section-sm-header {
      color: #81cbde;
    }
  }

    .benefits__item {
      min-height: 25rem;
      padding-top: 5rem;
      padding-bottom: 1rem;
    }
      .benefits__item-header {
        margin-bottom: 1.785717em;
      }

      .benefits__icon {
        display: inline-block;
      }

      .benefits__title {
        padding-left: 0.5em;
        line-height: 2.777777em;
        font-weight: bold;
        color: #C6D634;
        text-transform: uppercase;
        display: inline-block;
        margin: 0;
        vertical-align: middle;
      }

  @media screen and (max-width: $screen-sm-max) {
    .benefits__item {
      min-height: 5rem;
    }
  }




// ==========================================================================
// Have Questions?
// ==========================================================================

.have-questions-wrapper{
  background: #e8f0f3;
  line-height: 40px;
  padding-top: 75px;
  padding-bottom: 75px;

  .wording{
    color: #393a48;
    font-size: 20px;
    display: inline-block;
    padding: 0 20px;
  }

  .btn{
    display: inline-block;
    float: none;
    vertical-align: top;
  }
}




// ==========================================================================
// Features
// ==========================================================================

.features-wrapper{
  padding-top: 100px;
  background: #fafafa;

  &__header{
    margin-bottom: 90px;
  }
}
.features{

  &__item{
    margin-bottom: 100px;

    img{
      padding-right: 20px;
      padding-left: 0;
      height: auto;
    }

    p{
      line-height: 25px;
      font-size: 16px;
    }
  }

  &__title{
    color: #00bffd;
    font-weight: 600;
    font-size: 18px;
    margin: 5px 0;
    line-height: 24px;
  }

}

@media screen and (max-width: $screen-sm-max) {
  .features-wrapper{
    padding-bottom: 60px;
  }
  .features__item{
    margin-bottom: 40px;

  }
}

@media screen and (max-width: $screen-sm-min) {
  .features__item{
    text-align: center;
  }
}







// ==========================================================================
// Schedule Free Demo
// ==========================================================================

.schedule-demo-wrapper{
  text-align: center;
  background: url('../images/football-3.jpg') no-repeat center center;
  background-size: cover;
  color: #fff;
  position: relative;

  &::before{
    display: block;
    content: "";
    position: relative;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: -moz-linear-gradient(left, rgba(0,189,251,0.5) 0%, rgba(0,0,0,0.5) 81%, rgba(0,0,0,0.5) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,189,251,0.5)), color-stop(81%,rgba(0,0,0,0.5)), color-stop(100%,rgba(0,0,0,0.5)));
    background: -webkit-linear-gradient(left, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    background: -o-linear-gradient(left, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    background: -ms-linear-gradient(left, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to right, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc00bdfb', endColorstr='#cc000000',GradientType=1 );
  }

  .learn-more{
    margin-top: 80px;
    padding-left: 30px;
    padding-right: 30px;

    @media screen and (min-width: $screen-sm-min) {
      & {
        padding-left: 80px;
        padding-right: 80px;
      }
    }
  }
  .teaser{
    position: relative;
    z-index: 20;
  }

  h2{
    font-size: 32px;
    line-height: 45px;
    font-weight: 700;

    &+span{
      font-size: 24px;
    }
  }
}

.request-demo_wrapper {
  form{
    display: block;
    margin: 70px auto 0px;
    width: 70%;

    .validation_error{
      display: none;
    }
    .gform_footer,
    .gform_body{
      float: left;
      width: 49%;
      clear: none;
      padding: 0 1%;
      margin-top: 0;
    }

    .ginput_container{
      margin-top: 0 !important;
    }
    input{
      width: 100% !important;
    }
    input[type="text"]{
      padding: 5px;
    }

    label{
      display: none !important;
    }


    li.gfield.gfield_error,
    li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning{
      background-color: transparent;
      margin: 0 !important;
      border: none;
      color: #fff !important;

      .gfield_description{
        color: #fff;
      }
    }
  }
}

.schedule-demo-wrapper .gform_confirmation_wrapper{
  padding: 100px 0 90px;
  font-size: 20px;
}

@media screen and (max-width: $screen-sm-max) {
  .request-demo_wrapper form .gform_footer,
  .request-demo_wrapper form .gform_body{
    float: none;
    width: 100%;
    padding: 0;
    margin-top: 15px;
  }
}





// ==========================================================================
// General Header Teaser
// ==========================================================================

.header-teaser{
  min-height: 50px;
  text-align: center;
  background: url('../images/football-5.jpg') no-repeat center center;
  background-size: cover;
  color: #fff;
  position: relative;
  padding-top: 155px;
  padding-bottom: 155px;
  margin-bottom: 60px;

  &::before{
    display: block;
    content: "";
    position: relative;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: -moz-linear-gradient(left, rgba(0,189,251,0.5) 0%, rgba(0,0,0,0.5) 81%, rgba(0,0,0,0.5) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,189,251,0.5)), color-stop(81%,rgba(0,0,0,0.5)), color-stop(100%,rgba(0,0,0,0.5)));
    background: -webkit-linear-gradient(left, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    background: -o-linear-gradient(left, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    background: -ms-linear-gradient(left, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    background: linear-gradient(to right, rgba(0,189,251,0.5) 0%,rgba(0,0,0,0.5) 81%,rgba(0,0,0,0.5) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc00bdfb', endColorstr='#cc000000',GradientType=1 );
  }

  .content{
    text-align: center;
    position: relative;
    z-index: 20;
    color: #fff;

    h1{
      padding: 0;
      margin: 0;
      font-weight: bold;
    }

    .sub-header{
      font-size: 19px;
      font-weight: 500;
      display: inline-block;
      opacity: 0.7;
      padding: 10px 0;
    }

  }

}

@media screen and (max-width: $screen-sm-max) {
  .header-teaser{
    min-height: auto;
    padding-top: 115px;
    padding-bottom: 115px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: $screen-xs-max) {
  .header-teaser{
    min-height: auto;
    padding-top: 65px;
    padding-bottom: 65px;

    .content h1{
      font-size: 28px;
    }
  }
}






// ==========================================================================
// IPAD SLIDER
// ==========================================================================

.ipad-carusel-wrapper{
  background: url('../images/iPadDemoBackground.jpg') no-repeat center center;
  background-size: cover;
  padding-top: 35px;

  .ipad{
    margin: 0 auto;
    width: 722px;
    height: 515px;
    position: relative;
    background: url('../images/iPadDemoDeviceEmptyTrimmedTransparency.png') no-repeat top left;
    background-size: cover;
    position: relative;
  }

  .carousel{
    width: 477px;
    position: absolute;
    top: 40px;
    left: 193px;
    border: 3px solid #000;
    -ms-transform: rotate(.3deg);
    -webkit-transform: rotate(.3deg);
    -o-transform: rotate(-.3deg);
    transform: rotate(-0.3deg);
    border-radius: 2px;

    .carousel-inner{
      background: #eee;
      img{
        width: 100%;
      }
    }

    &::before{
      display: block;
      position: relative;
      content: "";
      width: 100%;
      height: 100%;
      background: url(../images/ipad-reflection.png) no-repeat top left;
      -webkit-background-size: cover;
      background-size: cover;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
    }

  }

  .carousel-control.left,
  .carousel-control.right{
    -ms-transform: rotate(0.3deg);
    -webkit-transform: rotate(0.3deg);
    transform: rotate(-0.3deg);
    background: linear-gradient(transparent);
    opacity: 0.8;
    position: fixed;
    z-index: 5;

    span{
      font-size: 55px;
      width: 55px;
      height: 55px;
      margin-top: -35px;
      z-index: 5;
    }
  }

  .carousel-control.left{
    left: -270px;
  }
  .carousel-control.right{
    right: -200px;
  }


  @media screen and (max-width: $screen-sm-max) {
    background: none;
    padding: 0;

    .carousel{
      position: relative;
      width: 100%;
      top: 0;
      left: 0;
      border: none;
      -ms-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);

      &:before{
        display: none;
      }
    }

    .ipad{
      background: none;
      width: 100%;
      height: auto;
    }

    .carousel-control.right{
      right: 0;
    }
    .carousel-control.left{
      left: 0;
    }
  }

}






// ==========================================================================
// SHOWCASE
// ==========================================================================

.showcase-wrapper {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  background: #fafafa;
}





// ==========================================================================
// REVEALCAM
// ==========================================================================

.revealcam-wrapper {
  padding-top: 10rem;
  padding-bottom: 10rem;
  overflow: hidden;
}

  .revealcam__header {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

    .revealcam__features {
      margin: 0;
      padding: 0;
    }

    .revealcam__item {
      list-style: none;
      font-size: 16px;
      color: #a3a3a3;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      padding-left: 4rem;
      position: relative;


      &:before {
        content: "";
        display: block;
        width: 25px;
        height: 25px;
        background: url(../images/ic_check.png) no-repeat;
        position: absolute;
        left: 0;
        top: 0;

        @media
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
          background: url(../images/ic_check@2x.png) no-repeat;
          background-size: contain;
        }
      }
    }

    .revealcam__ipad {
      transform: translateY(-20rem);
    }

  .revealcam__download {
    margin-top: 7rem;
  }





// ==========================================================================
// TESTIMONIALS
// ==========================================================================

.testimonials-wrapper {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: linear-gradient(-180deg, rgba(0,0,0,0.91) 0%, rgba(0,0,0,0.26) 100%), url(../images/testimonials-bg.jpg);
  background-size: cover;
}

  .testimonials__header {
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 5rem;
  }

  .testimonials__item {
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.66);
    border-radius: 3px;
    padding: 1.5rem;
    margin-bottom: 5rem;
    position: relative;
  }

    .testimonials__sport-type {
      position: absolute;
      top: 1rem;
      right:  1rem;
      width: 35px;
      height: 35px;
      background: top left no-repeat;

      &.is-football {
        background-image: url(../images/ic_line-football.png);
      }
      &.is-baseball {
        background-image: url(../images/ic_line-baseball.png);
      }
      &.is-snow-sports {
        background-image: url(../images/ic_line-snow-sports.png);
      }
    }

    .testimonials__avatar-container {
      height: 20rem;
      width: 20rem;
      overflow: hidden;
      border-radius: 50%;
      margin: 1rem auto 2rem;
    }

      .testimonials__avatar {
        width: 100%;
      }

      .testimonials__name {
        margin: 0.5rem 0;
      }

      .testimonials__team {
        display: block;
        color: #b0b0b0;
      }

  .testimonials__copy {
    text-align: left;
    color: #888;
    margin: 0;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
