.posts-list{

  .post{
    margin-bottom: 55px;

    .entry-title {
      margin: 10px 0;
      font-size: 2.2rem;
      line-height: 1.5;
    }

    .entry-summary{
      margin-top: 20px;
      text-align: justify;
      line-height: 1.5;
    }

    .read-more{
      margin-top: 15px;
    }

  }

}

.single main{

  header{
    padding: 20px 0;
  }

  .entry-title {
    font-size: 2.6rem;
    font-weight: 600;
  }


  p{
    line-height: 1.6;
    font-size: 1.6rem;
    margin: 15px 0;
  }

  h2{
    padding: 25px 0 10px;
    font-size: 2rem;
    font-weight: 600;
    display: block;
    width: 100%;
    clear: both;
  }

  h3{
    padding: 25px 0 10px;
    font-size: 1.7rem;
    font-weight: 600;
    display: block;
    width: 100%;
    clear: both;
  }

  img{
    margin: 0 15px 10px 0;
  }

  .comments{
    margin: 30px 0 50px;
  }

}

.post-navigation{
  padding: 30px 0 60px;

  a{
    margin: 0 10px;
  }

}

aside.sidebar{

  .widget_categories ul li{
    margin-bottom: 10px;
  }

  section{
    padding-bottom: 15px;
    border-top: 1px solid #eee;

    h3{
      padding-bottom: 20px;
    }

    &:first-child{
      h3{
        padding: 25px 0 10px;
      }
    }
  }

  .post-date{
    clear: both;
    display: block;
  }

  ul{
    display: block;
    padding: 0;
    list-style: none;

    li{
      margin-bottom: 20px;
    }
  }

}

