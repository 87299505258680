@font-face {
    font-family: 'insightreplay';
    src:url('../fonts/insightreplay.eot?x93jaz');
    src:url('../fonts/insightreplay.eot?x93jaz#iefix') format('embedded-opentype'),
        url('../fonts/insightreplay.ttf?x93jaz') format('truetype'),
        url('../fonts/insightreplay.woff?x93jaz') format('woff'),
        url('../fonts/insightreplay.svg?x93jaz#insightreplay') format('svg');
    font-weight: normal;
    font-style: normal;
}

i {
    font-family: 'insightreplay';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: middle;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.ir-icon-github:before {
  content: "\e90d";
}
.ir-icon-aperture:before {
    content: "\e900";
}
.ir-icon-baseball:before {
    content: "\e901";
}
.ir-icon-books:before {
    content: "\e902";
}
.ir-icon-check:before {
    content: "\e903";
}
.ir-icon-clock:before {
    content: "\e904";
}
.ir-icon-football:before {
    content: "\e905";
}
.ir-icon-globe:before {
    content: "\e906";
}
.ir-icon-snowflake:before {
    content: "\e907";
}
.ir-icon-target:before {
    content: "\e908";
}
.ir-icon-tv:before {
    content: "\e909";
}
.ir-icon-facebook:before {
    content: "\e90a";
}
.ir-icon-linkedin:before {
    content: "\e90b";
}
.ir-icon-twitter:before {
    content: "\e90c";
}

