// ==========================================================================
// Landing Pages Styles
// ==========================================================================
.landing-page{

  .section-sm-header{
    font-size: 14px;
    line-height: 19px;
  }
  .section-sm-header-plus{
    font-size: 16px;
    line-height: 21px;
  }
  .section-lg-header{
    font-size: 28px;
    line-height: 38px;
  }
  .ir-reveal-tm{
    font-variant: normal;
  }
  .benefits-wrapper{
    background: #fafafa;
    color: #a3a3a3;

    .benefits{

      .benefits__item-header{
        line-height: 35px;

        i{
          font-size: 35px;
          margin-right: 15px;
          color: #00bffd;
        }

        .benefits__title{
          color: #00bffd;
          padding: 0;
          line-height: inherit;
        }
      }

      p{
        font-size: 16px;
      }

      &__header{
        margin-bottom: 4rem;

        .section-sm-header{
          color: #afafaf;
        }

        .section-lg-header{
          color: #393a48;
        }

      }

      &__item{
        padding: 15px 25px;
      }

    }

  }

}

.lp-olympic-games{
  .schedule-demo-wrapper{
    background-image: url('../images/reveal-us-slopestyle-podium-sweep.jpg');
  }
  .ipad-carusel-wrapper{
    background-image: url('../images/Reveal-sochi-aerials-venue.jpg');
  }
}

.lp-baseball{
  .schedule-demo-wrapper{
    background-image: url('../images/baseball-header.jpg');
  }
  .ipad-carusel-wrapper{
    background-image: url('../images/baseball-ipad-bg.png');
  }
}

.lp-lacrosse{
  .schedule-demo-wrapper{
    background-image: url('../images/insight-replay-lacrosse.jpg');
  }
  .ipad-carusel-wrapper{
    background-image: url('../images/reveal-lacrosse-ipad-bg.jpg');
  }
}
