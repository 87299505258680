/* ==========================================================================
   Button Styles
   ========================================================================== */

$lightblue: #00BFFD;
$lightgreen: #C6D634;

/*
   Reusable styles
   ========================================================================== */

.btn-rnd {
  border-radius: 100em;
  transition: all 0.25s ease-in;
}

.btn-outline {
  background: none;
  padding: 0.8rem 2.5rem;
  font-weight: bold;
}

.is-fat {
  border: 2px solid;
}

.is-thin {
  border: 1px solid;
}

.is-white {
  color: #fff;
  border-color: #fff;

  &:hover,
  &:active,
  &:focus {
    background: $lightblue;
    color: #fff;
    border-color: #fff;
  }

}

.is-lightblue {
  color: $lightblue;
  border-color: $lightblue;

  &:hover,
  &:active,
  &:focus {
    background: $lightblue;
    color: #fff;
    border-color: #fff;
  }
}

.is-lightgreen-reverse {
  background: $lightgreen;
  color: #fff;
  border-color: #fff;

  &:hover,
  &:active,
  &:focus {
    color: $lightgreen;
    border-color: $lightgreen;
    background: none;
  }
}

.is-gradientblue {
  background-color: $lightblue;
  background-image: linear-gradient(transparent, rgba(0,0,0,.2));
  height: 100%;
  color: #fff;
  transition: all 0.25s ease-in;

  &:hover,
  &:active,
  &:focus {
    color: #fff;
    background-color: lighten($lightblue, 15%);
  }
}

.btn-cta {
  font-size: 1.6rem;
}

  .btn-cta__copy {
    margin-right: 1.25rem;
  }




/*
   Specific styles
   ========================================================================== */

.btn-navbar {
  margin-left: 2rem;
  font-size: 1.2rem;
}
